textarea {
  overflow: auto;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 21px;
  resize: none;
}

input {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 21px;
}

fieldset {
  border: 0px;
  padding: 0px;
  margin: 0px;
}

:focus {
  outline: 0;
}

.separator {
  clear: both;
  display: block;
}

.virgo-form {
  display: block;
}

.red-form {
  width: 100%;
  overflow: hidden;

  @media (max-width: 480px) {
    height: 100%;
    padding-bottom: 70px;
  }

  .form-header {
    width: 100%;
    padding: 20px 80px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    background: #0366b2;
    background: -moz-linear-gradient(45deg, #0366b2 1%, #0e97cf 750%);
    background: -webkit-linear-gradient(45deg, #0366b2 1%, #0e97cf 750%);
    background: linear-gradient(45deg, #0366b2 1%, #0e97cf 75%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0366b2', endColorstr='#044878', GradientType=1);
    box-shadow: 0px 5px 10px rgba(103, 98, 90, 0.5);

    @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }

    .logo img {
      max-width: 180px;
      padding-bottom: 20px;
    }
    
  }

  .form-body {
    padding: 20px 30px;
    position: relative;

    p {
      font-size: 16px;
      font-weight: 300;
      color: #505050;

      @media (max-width: 767px) {    
          font-size: 15px;
      }
      @media (max-width: 480px) {
        font-size: 15px;
      }

      &.small {
        font-size: 12px;
        line-height: 16px;
        text-align: justify;
        padding: 0 20px;
      }
    }

    input,
    select,
    textarea {
      background-color: #f5f5f5;
      border: 1px solid #d6d6d6;

      // @media (max-width: 480px) {
      //   width: 100%;
      // }
    }

    form {
      margin-bottom: 20px;
    }

    label {
      margin-bottom: 10px;
      padding-top: 8px;
      padding-right: 5px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      color: #505050;

      &.form-check-label {
        padding-left: 10px;
      }

      @media (max-width: 480px) {
        // width: 100%;
        // margin-bottom: 4px;
        padding-top: 6px;
        font-size: 16px;
        text-align: left;
      }
    }

    label.gradient {
      text-align: right;
      background: #81a3bb;
      background: -moz-linear-gradient(top, #81a3bb 1%, #b58597 51%, #d07b8b 100%);
      background: -webkit-linear-gradient(top, #81a3bb 1%, #b58597 51%, #d07b8b 100%);
      background: linear-gradient(to bottom, #81a3bb 1%, #b58597 51%, #d07b8b 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#81a3bb', endColorstr='#d07b8b', GradientType=0);
    }

    .checkbox label {
      background: transparent;
    }

    .disable-option {
      color: #757575 !important;
    }

    textarea {
      width: 70%;
      height: 120px;
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 300;
    }

    button[type="submit"] {
      width: 370px;
      height: 55px;
      margin: 10px auto 0;
      background-color: #0e97cf;
      font-size: 18px;
      color: #fff;
      display: block;
      cursor: pointer;
      transition: all 0.5s ease;
      box-shadow: 0px 5px 10px rgba(103, 98, 90, 0.2);

      @media (max-width: 480px) {
          width: 100%;
          font-size: 16px;
      }
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="visible"],
    select {
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
      padding: 10px 12px;
      font-family: "Lato", sans-serif;
      font-size: 16px;
      font-weight: 300;
      border-radius: 0px;
    }

    .status {
      width: 100%;
      height: 100%;
      padding-top: 10%;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }

    .status.success {
      color: #008d07;
    }

    .status.error {
      color: #bc1010;
    }

    .dropzone {
      background-color: #f5f5f5;
      border: 1px solid #d6d6d6;
      font-size: 18px;
      color: #5a5a5a;

      .dz-remove {
        position: absolute;
        top: -10px;
        right: -10px;
        color: #ff0000;
        font-weight: 800;
        z-index: 999;
        font-size: 32px;
        cursor: pointer;
      }
    }
  }
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    background-color: #f5f5f5;
    border: 1px solid #d6d6d6;
    background-image: none;
}

.form-col {
  width: 50%;
  padding: 0 20px;
  float: left;

  @media (max-width: 991px) {
    width: 100%;
  }

  &:nth-child(3) {
    float: right;
  }
}

.form-col-pc {
  float: none;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.copyright-form {
  display: block;
  clear: both;
  padding: 0;
}

.label-logo {
  height: 40px;
}

.logo-text {
  font-size: 16px;
  color: #505050;
  margin-left: 16px;
  margin-top: 6px;
  font-weight: bold;
}

.choice {
  font-size: 18px;
  font-weight: 300;
  padding: 0px 20px;

  @media (max-width: 640px) {
    padding: 0 0 0 10px;
  }
}

.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #fff;
  color: #505050 !important;
  top: 10px;
  height: 30px;
  width: 30px;
  border: 2px #505050 solid !important;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 17px;
  outline: none;

  @media (max-width: 640px) {
    margin-right: 7px;
  }

  &:checked::before {
    position: absolute;
    font: 13px/1 "Open Sans", sans-serif;
    left: 50%;
    top: 50%;
    content: "\02143";
    transform: translate(-50%, -50%);
    border-radius: 50px;
    color: transparent;
    width: 18px;
    height: 18px;
    background: #505050;
  }

  &:hover {
    background-color: #f7f7f7;
  }

  &:checked {
    background-color: #f1f1f1;
  }
}

.logo-desc {
  font-size: 16px;
  line-height: 21px;
  color: #505050;
  font-weight: 300;
  padding: 20px;
  display: block;
}

.upload-section {
  width: 100%;
  height: 200px;
  background: #f0f0f0;
  border-color: #f0f0f0;
}

.fileRow {
  position: relative;
  background: #f0f0f0;
  display: none !important;

  .upload-logo {
    position: absolute;
    top: 0;
    left: 10%;
    width: 200px;
    height: 200px;
    object-fit: contain;
    display: none;
  }

  .dblock {
    display: block;
  }

  &.dblock {
    display: block !important;
  }

  .file-button {
    background: transparent !important;
    border: 1px #d2d2d2 solid;
    color: #5a5a5a;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 20px !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
  }

  .file-button:hover {
    cursor: pointer;
  }
}

.inputfile {
  visibility: hidden;
}

.valRequired {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  // position: relative;
  background-color: #fff;
  color: #505050 !important;
  top: 5px;
  height: 20px;
  width: 20px;
  border: 2px #505050 solid !important;
  cursor: pointer;
  margin-right: 17px;
  outline: none;
}

.valRequired:checked::before {
  position: absolute;
  font: 13px/1 "Open Sans", sans-serif;
  left: 50%;
  top: 50%;
  content: "\02143";
  transform: translate(-50%, -50%);
  color: transparent;
  width: 10px;
  height: 10px;
  background: #505050;
}

.red-form .form-body button[type="submit"]:hover {
  background-color: #0e97cf;
}

.red-form .form-body button[type="submit"].fixed {
  position: static;
  bottom: 0px;
  width: 300px;
  left: -30px;
  right: -30px;
  transition: all 0.5s ease;
  border-radius: 5px;
  border-width: 0px;
}

.red-form .form-body input.error,
.red-form .form-body select.error {
  background-color: #f9d1db;
  color: #9b0529;
}

.selectcolor {
  color: #747474;
}

/* .red-form .form-body span.loader {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8) url(../images/main-loader.gif) no-repeat center;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  } */

.account {
  width: 100%;
  padding-top: 13%;
  position: absolute;
  left: 0;
  display: none;

  > p {
    margin-top: 10px;
    text-align: center;
    line-height: 22px;
  }

  > p.info {
    font-size: 18px;
    font-weight: 600;
  }

  > p span {
    margin-top: 10px;
    font-style: italic;
    display: block;
  }

  > p a {
    color: #a9022e;
    font-weight: 700;
  }

  > p a:hover {
    text-decoration: underline;
  }
}

.formRow {
  overflow: hidden;
  display: flex;
}

.formRow-pc {
  display: block;
  overflow: visible;
  margin-bottom: 40px;
}

.rowhalf {
  display: flex;
}

.rowhalf .formRow {
  width: 100%;
}

#company-person.dnone,
#companyName.dnone {
  display: none;
}

#company-person.dblock,
#companyName.dblock {
  display: flex;
}

.rowhalf .formRow:first-child {
  padding-right: 10px;
}

.formRow.checkbox label {
  width: 100%;
  text-align: justify;
  position: relative;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 10px;
  color: #505050;
}

.formRow.checkbox label input {
  margin-right: 0px;
}

.formRow.checkbox .errorMsg {
  position: absolute;
  bottom: -20px;
  padding-left: 0px;
}

.checkboxRow {
  margin: 0px;
  &:first-child {
    padding: 20px 0 0;
  }

  @media (max-width: 991px) {
    padding: 0;
  }
}

.infoFormBox {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  padding-top: 10px;
}

.formRow.radioboxRow {
  width: 100%;
  text-align: center;
}

.formRow.radioboxRow .center-block {
  width: 250px;
  margin-left: 30%;
  overflow: hidden;
}

.formRow.radioboxRow .center-block .sleft {
  float: left;
  margin-right: 20px;
}

.formRow.radioboxRow .center-block .sleft input {
  margin-left: 10px;
  margin-top: 15px;
}

.formRow.has-error {
  background-color: #d29696;
  border: 1px solid #841e1e;
  padding: 10px 10px 0px 10px;
  margin: 5px;
}

.footer {
  height: 20px;
  width: 100%;
  background: #0366b2;
  background: -moz-linear-gradient(45deg, #0366b2 1%, #aa0f2b 750%);
  background: -webkit-linear-gradient(45deg, #0366b2 1%, #aa0f2b 750%);
  background: linear-gradient(45deg, #0366b2 1%, #aa0f2b 75%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0366b2', endColorstr='#044878', GradientType=1);
  box-shadow: 0px 5px 10px rgba(103, 98, 90, 0.5);
  position: fixed;
  bottom: 0;
}
